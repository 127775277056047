<template>
  <div id="login" class="login-page">
    <div class="main-container">
    <!--<div class="col-one col50"></div>-->
      <div class="logn-form-cont">
        
        <div class="form-container">
        <div class="col-logo">
          <img src="../assets/Cimarrones-mx.png" alt="cimarrones" >
        </div>
          <h4 class="titulo-h4">Login</h4>
            <form id="loginapp"   @submit.prevent="login" action="" method="post">  
            <div class="row-block">
              <label for="correo" class="color-2 lb-form">Correo</label>
              <input 
                  id="correo" 
                  v-model="email_form" 
                  type="email" 
                  name="correo" 
                  required
                  
              >
            </div>
            <div class="row-block">
              <label for="password" class="color-2 lb-form">Contraseña</label>
              <input 
                  id="password" 
                  v-model="password_form" 
                  type="password" 
                  name="password" 
                  required
                            
              >
            </div>
            <p v-if="status=='error'" class="error">{{msg}}</p>
            <div class="btn-login">
              <input type="submit" class="" value="Iniciar Sesión">
            </div>
          </form>
          <div class="v-reg-cont">
            <p class="txt-version">V 1.0.0</p>
            <p class="reg-txt">¿No tienes cuenta? <a class="a-signin" href="/signin">Únete aquí</a></p>
            
          </div>
      <div class="sm-cont-log">
        <a href="#"><img src="../assets/fb-log.png" alt="fb" class="sm-logo"></a>
        <a href="#"><img src="../assets/ins-log.png" alt="ins" class="sm-logo"></a>
        <a href="#"><img src="../assets/in-log.png" alt="in" class="sm-logo"></a>
        <a href="#"><img src="../assets/tw-log.png" alt="tw" class="sm-logo"></a>
      </div>
        </div>
      </div>
     
    </div>
  </div>
</template>
<script>

import ls from '../services/login.service'
import userService from '../services/user.service'
export default {
  name: 'login',
  data: () => ({
    email_form: "",
    password_form: "",
    status:'',
    msg:''
  }),
  computed:{
    token() {
      return this.$store.getters['admin/getToken'];
    },
    identity() {
      return this.$store.getters['admin/getIdentity'];
    }
  },
  methods: {
    async login() {
       
      
       try {
        let response_token = await this.$store.dispatch("admin/login", {
            email: this.email_form,
            password: this.password_form
        });
         
        //console.log(response_token)
        //console.log(response_token.data.status)
       
        if (response_token.data.status != 'error') {
          this.status = 'success';
          this.$store.dispatch("admin/setToken", this.token);          
          this.$store.dispatch("admin/getToken");
          this.$store.dispatch("admin/decode", this.token);

        let isAdmin = ls.isAdmin();

        if(isAdmin){
            // this.$router.push("/auth").catch(err => {}); de momento hasta encontrar solucion para envio de email desde un servidor 

             let ath = userService.createAuth();
             userService.setAth(ath);
             this.$router.push("/administrador").catch(err => {});
        }else{
           this.$router.push("/").catch(err => {});
        }
        
         
        } else {
          this.status = 'error';
          if(response_token.data.message =='Contraseña no valida'){
              this.msg='La contraseña que ingresaste no coincide con nuestros registros. Por favor, revisa e inténtalo de nuevo.'
          }else{
              this.msg='El correo electrónico que ingresaste no coincide con nuestros registros. Por favor, revisa e inténtalo de nuevo.'
          }
        }
         
       
      } catch (error) {
        this.status ='error'
       
      }
       
    }
  },


  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Login - Cimarrones de Sonora';
            }
        },
    }
}
</script>


